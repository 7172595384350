.Cover-Products
{
    background-color: #a5eee4;
    padding: 20px;
    margin: 20px;
}
#Contact-Body
{
    color: black;
    font-family: 'Nunito';
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 2rem;
    border: 1px solid #3bafb3;
    border-radius: 0.8rem;
    margin-bottom: 2rem;
}
.Map
{
    width: 100%;
    height: 500px;
}

@media (max-width: 760px) {
    #Contact-Body
    {
        grid-template-columns: 100%;
    }
}
.Contact
{
    text-align: center;
}
.Contact1
{
    text-align: center;
    color: #3bafb3;
}
.Contact-Holder
{
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content:space-around;
}
.Contact-Holder2
{
    padding: 2rem;
}