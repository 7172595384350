.LoginNavigation
{
    background-color: white;
    border-bottom: 1px solid #e6e6e6;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1000;
    padding: 1rem;
    box-shadow: 0 1px 2px rgba(0,0,0,.1);

}
.FormContainer
{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10rem;    
    margin-bottom: 5rem;
}
.form_container {
    width:fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 50px 40px 20px 40px;
    background-color: #ffffff;
    box-shadow: 0px 106px 42px rgba(0, 0, 0, 0.01),
      0px 59px 36px rgba(0, 0, 0, 0.05), 0px 26px 26px rgba(0, 0, 0, 0.09),
      0px 7px 15px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 11px;
    font-family: "Inter", sans-serif;
  }
  
  .logo_container {
    box-sizing: border-box;
    width: 80px;
    height: 80px;
    background: linear-gradient(180deg, rgba(248, 248, 248, 0) 50%, #F8F8F888 100%);
    border: 1px solid #F7F7F8;
    filter: drop-shadow(0px 0.5px 0.5px #EFEFEF) drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
    border-radius: 11px;
  }
  
  .title_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .title {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 700;
    color: #212121;
  }
  
  .subtitle {
    font-size: 0.725rem;
    max-width: 80%;
    text-align: center;
    line-height: 1.1rem;
    color: #8B8E98
  }
  
  .input_container {
    width: 100%;
    height: fit-content;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .icon {
    width: 20px;
    position: absolute;
    z-index: 99;
    left: 12px;
    bottom: 9px;
  }
  
  .input_label {
    font-size: 0.75rem;
    color: #8B8E98;
    font-weight: 600;
  }
  
  .input_field {
    width: auto;
    height: 40px;
    padding: 0 0 0 40px;
    border-radius: 7px;
    outline: none;
    border: 1px solid #e5e5e5;
    filter: drop-shadow(0px 1px 0px #efefef)
      drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
    transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
  }
  
  .input_field:focus {
    border: 1px solid transparent;
    box-shadow: 0px 0px 0px 2px #242424;
    background-color: transparent;
  }
  
  .sign-in_btn {
    width: 100%;
    height: 40px;
    border: 0;
    background: #3bafb3;
    border-radius: 7px;
    outline: none;
    color: #ffffff;
    cursor: pointer;
  }
  
  .sign-in_ggl {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: #ffffff;
    border-radius: 7px;
    outline: none;
    color: #242424;
    border: 1px solid #e5e5e5;
    filter: drop-shadow(0px 1px 0px #efefef)
      drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
    cursor: pointer;
  }
  
  .sign-in_apl {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: #212121;
    border-radius: 7px;
    outline: none;
    color: #ffffff;
    border: 1px solid #e5e5e5;
    filter: drop-shadow(0px 1px 0px #efefef)
      drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
    cursor: pointer;
  }
  
  .separator {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    color: #8B8E98;
  }
  
  .separator .line {
    display: block;
    width: 100%;
    height: 1px;
    border: 0;
    background-color: #e8e8e8;
  }
  
  .note {
    font-size: 0.75rem;
    color: #8B8E98;
    text-decoration: underline;
  }
  .ButtonContainer
  {
    margin-top: 8rem;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 1rem;
  }
  .ButtonContainer button{
    background-color: #3bafb3;
    border: 1px solid white;
    color: white;
    padding: 0.7rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    font-family: 'Rubik';
    font-weight: 500;
    border-radius: 0.3rem;
    transition: all 0.2s;
    cursor: pointer;
  }
  .ButtonContainer button:hover
 {
    border: 1px solid #3bafb3;
    background-color: white;
    color: #3bafb3;

}

  .QuoteContainer
  {
    margin: 1.5rem;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    padding: 3rem;
    padding-top: 0;
    padding-bottom: 0;
    border: 2px solid #3bafb3;
  }
  .Main-Details
  {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 1rem;
  }
  .Quote-Details
  {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: left;
    padding: 1rem;
  }


  .ButtonContainer3
  {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 1rem;
  }
  .ButtonContainer3 button{
    background-color: #3bafb3;
    border: 1px solid white;
    color: white;
    padding: 0.7rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    font-family: 'Rubik';
    font-weight: 500;
    border-radius: 0.3rem;
    transition: all 0.2s;
    cursor: pointer;
  }
  .ButtonContainer3 button:hover
 {
    border: 1px solid #3bafb3;
    background-color: white;
    color: #3bafb3;

}


@media (max-width: 768px) {
    .QuoteContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        margin-left: -0.4rem;
        border: 2px solid #3bafb3;
    }
    .Main-Details
    {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .ButtonContainer3 button{
        width: 100%s;
        font-size: 0.9rem;
    }

}