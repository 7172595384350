.AFrameWrapper
{
    background-color:#d3f3ef;
    width: 100%;
}

.AFrameCover
{
    display: grid;
    grid-template-columns: 60% 40%;
    margin-top: -3rem;
    width: 100%;
    background-color:#d3f3ef;
    z-index: -1;
    padding: 1rem;
    padding-top: 5rem;
}
.AFramePT1
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}
.AFramePT1 h2
{
    font-family: 'Nunito', sans-serif;;
    font-size: 2.2rem;
    font-weight: 500;
    color: rgb(229, 85, 85);
}
.AFramePT1 p
{
    font-family: 'Nunito', sans-serif;;
    font-size: 1rem;
    font-weight: 400;
    color: rgb(0, 0, 0);
    margin-top: 1rem;
    text-align: justify;
}

.AFramePT2
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Cover-Sidebar-Image-Container
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 90%;
}
.Cover-Sidebar-Image-Container img
{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.AFramePT3
{
    display: flex;
    justify-content:space-between;
    align-items: center;
    font-weight: 400;
    font-size: 1rem;
    color: rgb(0, 0, 0);

}
.AFramePT3Wrapper
{
    padding: 0.5rem;
    background-color: white;
    padding-bottom: 0rem;
    box-shadow: 0px 0px 5px 0px #d3f3ef;
    border-bottom: 1px solid gray;
    border-top: 1px solid gray;
}

.AProductsDisplay
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    background-color: white;
    text-align: center;
}
.AProductsDisplay div
{
    text-align: left;
}

.AProductsDisplay div h2
{
    font-family: "Rubik";
    font-size: 1.8rem;
    font-weight: 500;
    color: rgb(0, 0, 0);
    margin-top: 2rem;
    margin-bottom: 0.5rem;
}
.AProductsDisplay div h2 span
{
    color: rgb(229, 85, 85);
}

.AProductsDisplay div p
{
    font-family: 'Nunito', sans-serif;;
    font-size: 1rem;
    font-weight: 400;
    color: rgb(0, 0, 0);
}
.AProductsDisplay-Inner
{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
.AProductsDisplay-1
{
    background-color: white;
    margin: 1rem;
    border-radius: 0.2rem;
    border: 1px solid #d3f3ef;
    box-shadow: 0px 2px 23px 0px #d3f3ef;
    -webkit-box-shadow: 0px 2px 23px 0px #d3f3ef;
    -moz-box-shadow: 0px 2px 23px 0px #d3f3ef;
    margin-bottom: 1rem !important;
}
.AProduct-Image
{
    padding: 0.3rem;
}
.AProduct-Image img
{
    width: 100%;
    height: 30%;
    object-fit: cover;
    border-bottom: 1px solid #d3f3ef;
}
.AProduct-Description
{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    padding-top: 0.5rem;
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;
}
.AProduct-Description h5
{
    font-family: 'Rubik', sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    color: rgb(0, 0, 0);
    text-align: left;
    margin-bottom: 1rem;
}
.AProduct-Description ul
{
    list-style:disc;
    font-family: 'Nunito', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: rgb(54, 54, 54);
    margin-top: -0.8rem;
    text-align: left;
}
.View-Product
{
    display: flex;
    justify-content: center;
    padding: 1rem;
    padding-top: 0;
    padding-bottom: 0;
}
.View-Product button
{
    width: 100%;
    background-color: #3bafb3;
    color: white;
    border: none;
    padding: 0.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.2rem;
    font-family: 'Nunito', sans-serif;;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.View-Product button:hover
{
    background-color: rgb(229, 85, 85);
}
.FAQs-Wrapper
{
    margin-top: 1rem;
    background-color: white;
    box-shadow: 0px 0px 5px 0px #d3f3ef;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.FAQs
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    text-align: left;
}
.FAQs p
{
    font-family: 'Nunito', sans-serif;;
    font-size: 1rem;
    font-weight: 400;
    color: rgb(0, 0, 0);
    margin-top: 1rem;
    text-align: justify;
}
@media (max-width:786px){
    .AFrameCover
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .AProductsDisplay-Inner
    {
        grid-template-columns: 50% 50% !important;
    }
}

@media (max-width:480px){
    .AFrameCover
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .AFramePT1
    {
        text-align: center;
    }
    .AFramePT2
    {
        margin-top: 1rem;
    }
    .AFramePT3Wrapper
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .AFramePT3
    {
        margin-top: 1rem;
    }
    .AProductsDisplay-Inner
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .AProductsDisplay-1
    {
        width: 90%;
    }
    .AProduct-Image img
    {
        height: 20%;
    }
    .AProduct-Description
    {
        padding: 0.5rem;
    }
    .View-Product button
    {
        width: 90%;
    }
    .FAQs-Wrapper
    {
        margin-top: 1rem;
    }
    .AProductsDisplay
    {
        text-align: left;
        font-size:.875em!important;
        padding: 0.5rem;
    }

}